export default {
  token: null,
  verify: {
    e: "",
    i: "",
  },
  errors: null,
  isAuth: false,
  loading: false,
};
